'use client';

import { useRouter } from 'next/navigation';
import { useProgress } from '@/components/MainLayout/hooks/useProgress';
import { startTransition } from 'react';

export const useHhRouter = () => {
    const router = useRouter();
    const { startLoading } = useProgress();

    const overwrittenPush = (path: string, options?: any) => {
        startTransition(() => {
            startLoading?.();
            router.push(path, options);
        });
    };

    return {
        ...router,
        push: overwrittenPush,
    };
};
